import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout/Layout'
import PreviewCompatibleImage from '../components/image/PreviewCompatibleImage'

export const BoutiquePageTemplate = ({
  image,
  title,
  heading,
  description,
  main,
}) => (

  <div className="content">
    <section className="section section--gradient" style={{ textAlign: 'justify' }}>
      <div className="container">
        <div className="columns has-text-justified">
          <div className="column is-6 has-text-justified">
            <h3 className="">{heading}</h3>

            <p>{description}</p>
            <p>{main.description}</p>
            <p>{main.description2}</p>
          </div>
          <div className="column is-6">
            <div className="row has-text-centered" style={{margin:'auto'}}>
              <PreviewCompatibleImage imageInfo={main.image0}/>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <div className="tile is-ancestor">
                  <div className="tile is-parent">
                    <article className="tile is-child">
                      <PreviewCompatibleImage imageInfo={main.image1} />
                    </article>
                  </div>
                  <div className="tile is-parent">
                    <article className="tile is-child">
                      <PreviewCompatibleImage imageInfo={main.image2} />
                    </article>
                  </div>
                <div className="tile is-parent">
                  <article className="tile is-child">
                    <PreviewCompatibleImage imageInfo={main.image3} />
                  </article>
                </div>
            </div>
            {/* <div
                className="full-width-image-container"
                style={{
                  backgroundImage: `url(${fullImage.childImageSharp
                    ? fullImage.childImageSharp.fluid.src
                    : fullImage
                    })`,
                }}
              /> */}
          </div>
        </div>
      </div>
    </section>
  </div>
)

BoutiquePageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image0: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

const BoutiquePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <BoutiquePageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        main={frontmatter.main}
      />
    </Layout>
  )
}

BoutiquePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default BoutiquePage

export const boutiquePageQuery = graphql`
  query BoutiquePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        heading
        description
        main {
          description
          description2
          image0 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 368, quality: 92, layout: CONSTRAINED)
              }
            }
            maxWidth
          }
          image1 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 368, quality: 92, layout: CONSTRAINED)
              }
            }
            maxWidth
          }
          image2 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 368, quality: 92, layout: CONSTRAINED)
              }
            }
            maxWidth
          }
          image3 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 368, quality: 92, layout: CONSTRAINED)
              }
            }
            maxWidth
          }
        }
      }
    }
  }
`
